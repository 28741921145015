import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client'

const httpLink = new HttpLink({  
  uri: process.env.GATSBY_API_URL,
  // credentials: 'include'
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    httpLink
  ])
});
